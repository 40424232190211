@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

body, html {
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

.our-button-color:hover,
.our-button-color:focus {
    background-color: #d65b00;
    border-color: #d65b00;
}

.ant-table, .ant-table-wrapper, .ant-table-cell,
.ant-menu, .ant-menu-item, .ant-dropdown-menu,
.ant-card, .ant-card-head, .ant-btn, .ant-input,
.ant-select, .ant-form, .ant-modal, .ant-popover,
.ant-tooltip, .ant-typography,
p, a, li, h1, h2, h3, h4, h5, h6,
button, td, label, strong, em, i, b, code, pre,
blockquote, span, div, nav, header, footer,
section, article, aside, form, table, caption,
thead, tbody, tfoot, tr, th {
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

.font-0 p, .font-0 a, .font-0 li, .font-0 h6,
.font-0 h5, .font-0 h4, .font-0 button, .font-0 td,
.font-0 label {
    font-size: 90% !important;
}

.font-2 p, .font-2 a, .font-2 li, .font-2 h6,
.font-2 h5, .font-2 h4, .font-2 button, .font-2 td,
.font-2 label {
    font-size: 115% !important;
}

.notificationBody {
    width: 100%;
}

.ant-pagination-item {
    background-color: transparent !important;
}

.ant-tabs-ink-bar-animated {
    display: none;
}

.ant-pagination-item-active {
    background-color: #3e79f7 !important;
}

.ant-menu-title-content {
    display: block;
    width: 30px;
}

.our-button-color-download {
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 8px 16px;
}

.our-button-color {
    color: white;
    border-radius: 3px;
    background-color: #FF7F11;
}

.our-button-color-cancel {
    border-radius: 3px;
    background-color: #4fb0c6;
    color: white;
}

.our-button-color-cancel:hover,
.our-button-color-cancel:focus {
    background-color: #388a9d;
    border-color: #388a9d;
}

.our-border-button-color {
    border-radius: 3px;
    border-color: #FF7F11;
    color: #FF7F11;
}

.our-border-button-color-blue-error {
    border-radius: 3px;
    border-color: #224368;
    color: #224368;
}

.filterBackground {
    margin-top: 15px;
    background-color: rgba(79, 176, 198, 0.05);
    padding: 20px;
    border-radius: 5px;
}

.navBarBackgroundColor {
    background-color: #224368;
}

.textBtn {
    color: #3391ff;
    cursor: pointer;
}

.navBarText {
    color: #f0f0f0;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.005em;
}

.navBarIcon {
    font-size: 22px !important;
    color: #f0f0f0;
}

.botomWhiteBorder {
    border-bottom: solid white;
}

.ant-menu-submenu-arrow {
    color: white !important;
}

@media (min-width: 768px) {
    .ant-tabs-nav-wrap, .ant-tabs-nav-list, .ant-tabs-tab {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .notificationBody {
        width: 265px;
    }

    .tableDiv {
        padding: 5px;
    }
}

.textNextToToImageNav {
    color: white !important;
}

.ant-menu-inline-collapsed .textNextToToImageNav {
    display: none;
}

.ant-menu-submenu-popup .textNextToToImageNav {
    color: black !important;
}

.ant-tabs-tab {
    background: white !important;
    margin-left: 0 !important;
}

.ant-tabs-tab-active {
    background-color: #FF7F11 !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
    font-weight: bold;
}

.ant-select-multiple {
    height: 41px;
}

.clickAble {
    color: #3391ff;
    cursor: pointer;
}

.btn-clear {
    border-radius: 3px;
    background-color: #4fb0c6;
    color: white;
}

.btn-filter {
    border-radius: 3px;
    background-color: #224368;
    border: none;
    color: white;
}

.btn-filter:hover,
.btn-filter:focus {
    background-color: #1a2b42;
    border:none ;
}

.btn-clear:hover,
.btn-clear:focus {
    background-color: #3a9ac6;
    border-color: #3a9ac6;
}

.tableDiv {
    padding: 24px;
}

.customCard {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
    overflow: hidden;
    padding: 24px;
    position: relative;
    word-wrap: break-word;
}

.required-label::before {
    content: "*";
    color: #ff4d4f;
    margin-right: 4px;
}

.icon-scroller {
    display: flex;
    overflow-x: auto;
    overflow-y: visible;
    white-space: nowrap;
    padding: 10px;
    position: relative;
    z-index: 1000;
}

.icon-container {
    display: inline-block;
    position: relative;
    padding: 10px;
    cursor: pointer;
    transition: transform 0.2s;
}

.icon-wrapper:hover {
    transform: scale(1.1);
}

.close-icon {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

.close-icon::before, .close-icon::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 20px;
    background-color: black;
    transform: translate(-50%, -50%) rotate(45deg);
}

.close-icon::after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.close-icon>span {
    background-color: transparent !important;
}

.blink-button {
    animation: blink 1s infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.ck-editor {
    overflow-y: auto;
}

.ck-editor__editable_inline {
    resize: vertical;
    overflow: auto;
    min-height: 200px;
    max-height: 800px;
}